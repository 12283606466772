@import "~bootstrap/scss/bootstrap.scss";

.product-list{
    font-family: 'Grandstander', cursive;
    .wrap-ripple{
        width:100%;
        height:100%;
        overflow: hidden;
        position: absolute;
        top:0;
        left:0;
        display: block;
        z-index:100;
        cursor: pointer;
    }
    .inner-product-list{
        position: relative;
        z-index:1;
        height:100%;
        overflow: hidden;
        .wrap-img{
            width: 150px;
            .product-image-list{
                width:150px;
                height:150px;
                object-fit: cover;
            }
            @media (max-width:400px) {
                width:100px;
                .product-image-list{
                    width:100px;
                    height:100px;
                }
            }
        }
    }
    @media (max-width:400px) {
        font-size:13px;
        margin-bottom:0;
        height:135px;
    }
    position: relative;
    height:185px;
}
.animate_{
    &delay-250ms{
        animation-delay: 250ms;
    }
    &delay-500ms{
        animation-delay: 500ms;
    }
    &delay-750ms{
        animation-delay: 750ms;
    }
    &delay-1000ms{
        animation-delay: 1000ms;
    }
    &delay-1250ms{
        animation-delay: 1250ms;
    }
    &delay-1500ms{
        animation-delay: 1500ms;
    }
}

