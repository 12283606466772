@import "~bootstrap/scss/bootstrap.scss";

.cart{
    font-family: 'Grandstander', cursive;
    position:fixed;
    bottom:0;
    color:#555;
    background:#ffffff;
    height:auto;
    .card{
        overflow:visible !important;
        border:none;
        .card-header{
            background-color: white !important;
        }
        .card-footer{
            background-color: white !important;
            border-top:none;
        }
    }
    .circle-open{
        transition: all 0.3s ease;
        width:50px;
        background:white;
        height:50px;
        position: absolute;
        top:-25px;
        right:20px;
        border-radius:50%;
        overflow: hidden;
        z-index:1000;
        svg{
            position:absolute;
            top:50%;
            left:50%;
            transition:all 0.2s ease;
            &.up{
                transform:translate(-50%,-50%) rotate(0deg);
            }
            &.down{
                transform:translate(-50%,-50%) rotate(180deg);
            }
        }
    }
}